// 导入封装好的网络请求类工具
import Network from './network';

// 封装各种接口请求
// export const 接口名 = () => Network.get('/路由',参数对象);
export const dologin = (data) => Network.post('admin/login/dologin', data); // 登录
export const userLogin = (data) => Network.get('admin/member/getLoginUserInfo', data); // 获取用户信息

// 获取航司船司所有信息
export const getAllList = (data) => Network.get('admin/ship_company/getAllList', data); // 获取航司船司所有信息

// 根据企业id 获取联系人
export const getAllLinkman = (data) => Network.get('admin/company_linkman/getLinkManByCompanyId', data);

// 报价
export const offerList = (data) => Network.post('admin/offer/index', data); // 报价列表
export const offerAdd = (data) => Network.post('admin/offer/add', data); // 报价添加
export const offerEdit = (data) => Network.post('admin/offer/edit', data); // 报价修改
export const offerShow = (data) => Network.get('admin/offer/show', data); // 报价详情
export const offerDel = (data) => Network.get('admin/offer/del', data); // 报价删除
export const offerOrder = (data) => Network.get('admin/offer/shiftToOrder', data); // 转入订单
export const offerShiftToTemplate = (data) => Network.post('admin/offer/shiftToTemplate', data); // 转入模板
export const offerCargoDel = (data) => Network.get('admin/offer/CargoDel', data); // 报价货物删除
export const offerCostDel = (data) => Network.get('admin/offer/CostDel', data); // 报价费用删除

// 成本编辑
export const degressionInfoAdd = (data) => Network.post('admin/degression_info/add', data); // 成本编辑添加
export const degressionInfoEdit = (data) => Network.post('admin/degression_info/edit', data); // 成本编辑修改
export const degressionInfoShow = (data) => Network.get('admin/degression_info/show', data); // 成本编辑详情
export const degressionInfoDel = (data) => Network.get('admin/degression_info/del', data); // 成本编辑删除

export const offerReceivable = (data) => Network.post('admin/degression_info/receivable', data); // 生成应付结算
export const checkReceivable = (data) => Network.post('admin/degression_info/checkReceivable', data); // 生成生成应付结算 是验证是否重复
// 报价模板
export const templateOfferList = (data) => Network.post('admin/template_offer/index', data); // 报价模板列表
export const templateOfferEdit = (data) => Network.post('admin/template_offer/edit', data); // 报价模板修改
export const templateOfferShow = (data) => Network.get('admin/template_offer/show', data); // 报价模板详情
export const templateOfferDel = (data) => Network.get('admin/template_offer/del', data); // 报价模板删除

export const templateOfferDelCost = (data) => Network.get('admin/template_offer/delCost', data); // 报价模板费用删除
export const templateOfferDelCargo = (data) => Network.get('admin/template_offer/delCargo', data); // 报价模板货物删除

export const templateMoveOffer = (data) => Network.get('admin/template_offer/templateMoveOffer', data); // 报价模板转报价

// 结算管理
export const costInfoList = (data) => Network.post('admin/cost_info/index', data); // 应收结算列表
export const costInfoShow = (data) => Network.get('admin/cost_info/show', data); // 应收结算详情
export const costInfoEdit = (data) => Network.post('admin/cost_info/edit', data); // 应收结算修改
export const costInfoDel = (data) => Network.get('admin/cost_info/del', data); // 应收结算删除
export const costInfoDelById = (data) => Network.get('admin/cost_info/delById', data); // 应收结算详情费用删除
export const costSettlementList = (data) => Network.post('admin/cost_info/settlementList', data); // 应收结算完成列表

export const costImageList = (data) => Network.get('admin/cost_image/newsIndex', data); // 应收凭证列表
export const costImageAdd = (data) => Network.post('admin/cost_image/newsAdd', data); // 应收凭证添加
export const costImageDel = (data) => Network.get('admin/cost_image/del', data); // 应收凭证删除

export const DegressionList = (data) => Network.post('admin/Degression/index', data); // 应付结算列表
export const DegressionShow = (data) => Network.get('admin/Degression/show', data); // 应付结算详情
export const DegressionEdit = (data) => Network.post('admin/Degression/edit', data); // 应付结算修改
export const DegressionDel = (data) => Network.get('admin/Degression/del', data); // 应付结算删除
export const DegressionWithdrawApi = (data) => Network.get('admin/degression/withdraw', data); // 应付结算撤回
export const DegressionDelById = (data) => Network.get('admin/Degression/delById', data); // 应付结算详情费用删除
export const DegressionSettlementList = (data) => Network.post('admin/Degression/settlementList', data); // 应付结算完成
export const DegressionImageList = (data) => Network.get('admin/receivable_image/newsIndex', data); // 应付凭证列表
export const DegressionImageAdd = (data) => Network.post('admin/receivable_image/newsAdd', data); // 应付凭证添加
export const DegressionImageDel = (data) => Network.get('admin/receivable_image/del', data); // 应付凭证删除


// 企业管理
export const companyList = (data) => Network.post('admin/company/index', data); // 企业列表
export const companyDel = (data) => Network.get('admin/company/del', data); // 企业列表删除
export const companyAdd = (data) => Network.post('admin/company/add', data); // 企业管理添加
export const companyDetail = (data) => Network.get('admin/company/show', data); // 企业管理详情
export const companyResourceDel = (data) => Network.get('admin/company_resource/del', data); // 删除企业资源
export const companyLinkmanDel = (data) => Network.get('admin/company_linkman/del', data); // 删除企业联系人
export const companyEdit = (data) => Network.post('admin/company/edit', data); // 企业修改
export const companyImgAdd = (data) => Network.post('admin/company_seniority/add', data); // 添加资质
export const companyImg = (data) => Network.get('admin/company_seniority/index', data); // 获取企业资质列表
export const companyImgDel = (data) => Network.get('admin/company_seniority/del', data); // 删除

// 订单查询
export const orderAdd = (data) => Network.post('admin/order/add', data); // 订单查询添加
export const orderCost = (data) => Network.post('admin/order/orderCost', data); // 保存应收订单
export const orderList = (data) => Network.post('admin/order/index', data); // 订单列表
export const orderDel = (data) => Network.get('admin/order/del', data); // 订单列表删除
export const orderDetail = (data) => Network.get('admin/order/show', data); // 订单详情
export const orderDelCargo = (data) => Network.get('admin/order/delCargo', data); // 订单货物删除
export const orderDelCost = (data) => Network.get('admin/order/delCost', data); // 订单费用删除
export const orderEdit = (data) => Network.post('admin/order/edit', data); // 订单修改
export const orderStatusAdd = (data) => Network.post('admin/order_status/add', data); // 订单状态添加
export const orderStatusList = (data) => Network.get('admin/order_status/index', data); // 获取订单状态列表
export const pushWarehouseReceipt = (data) => Network.get('admin/order/pushWarehouseReceipt', data); // 转让定仓单
export const salesAnnotationsIndex = (data) => Network.get('admin/sales_annotations/index', data); // 根据订单id获取销售批注列表
export const salesAnnotationsSaveData = (data) => Network.post('admin/sales_annotations/saveData', data); // 添加销售批注
export const salesAnnotationsEditData = (data) => Network.post('admin/sales_annotations/editData', data); // 更新销售批注
export const salesAnnotationsDeleteData = (data) => Network.get('admin/sales_annotations/deleteData', data); // 删除销售批注
export const getSaleUserList = (data) => Network.get('admin/manager/getSaleUserList', data); // 获取销售人员列表

export const financialVerificationAdd = (data) => Network.post('admin/financial_verification/add', data); // 添加财务批注
export const financialVerificationIndex = (data) => Network.post('admin/financial_verification/index', data); // 财务批注列表
export const financialVerificationUpdata = (data) => Network.post('admin/financial_verification/update', data); // 修改财务批注
export const financialVerificationDetail = (data) => Network.get('admin/financial_verification/detail', data); // 财务批注详情
export const financialVerificationDelete = (data) => Network.get('admin/financial_verification/delete', data); // 财务批注删除


// 订舱单列表
export const dcList = (data) => Network.post('admin/warehouse_receipt/index', data); // 订舱单列表
export const editPrice = (data) => Network.post('admin/warehouse_receipt/editPrice', data); // 修改价格
export const dcDel = (data) => Network.get('admin/warehouse_receipt/del', data); // 删除
export const dcAdd = (data) => Network.post('admin/warehouse_receipt/add', data); // 添加
export const dcDetail = (data) => Network.get('admin/warehouse_receipt/show', data); // 详情
export const dcEdit = (data) => Network.post('admin/warehouse_receipt/edit', data); // 编辑
export const dcDelCagro = (data) => Network.get('admin/warehouse_receipt/delCagro', data); // 货物删除

// 报表
export const business = (data) => Network.post('admin/finance/business', data); // 业务数据
export const showBusinessEchartData = (data) => Network.post('admin/finance/showBusinessEchartData', data); // 根据类型获取业务数据echart
export const finance = (data) => Network.post('admin/finance/finance', data); // 财务数据
export const showFinanceEchartData = (data) => Network.post('admin/finance/showFinanceEchartData', data); // 根据类型获取财务数据echart

// 参数设置
export const paramsApi = (url, data) => Network.post(url, data); //参数的添加和修改

export const serviceType = (data) => Network.post('admin/service_type/index', data); //服务类型
export const delServiceType = (data) => Network.get('admin/service_type/del', data); //删除服务类型

export const transport = (data) => Network.post('admin/transportation/index', data); //运输流向
export const delTransport = (data) => Network.get('admin/transportation/del', data); //删除运输流向

export const tspMode = (data) => Network.post('admin/transport/index', data); //运输方式
export const delTspMode = (data) => Network.get('admin/transport/del', data); //删除运输方式

export const services = (data) => Network.post('admin/services_scope/index', data); //服务范围
export const delServices = (data) => Network.get('admin/services_scope/del', data); //删除服务范围

export const startHarbor = (data) => Network.post('admin/shipment_harbor/index', data); //起运港
export const delStartHarbor = (data) => Network.get('admin/shipment_harbor/del', data); //删除起运港
export const importStartHarbor = (data) => Network.post('admin/shipment_harbor/import', data); // 起运港导入

export const endHarbor = (data) => Network.post('admin/bourn_harbor/index', data); //目的港
export const delEndHarbor = (data) => Network.get('admin/bourn_harbor/del', data); //删除目的港
export const importEndHarbor = (data) => Network.post('admin/bourn_harbor/import', data); // 目的港导入

export const costName = (data) => Network.post('admin/cost_name/index', data); //费用名称
export const delCostName = (data) => Network.get('admin/cost_name/del', data); //删除费用名称
export const importCostName = (data) => Network.post('admin/cost_name/import', data); // 费用名称导入

export const payDays = (data) => Network.post('admin/payment_days/index', data); //结算账期
export const delPayDays = (data) => Network.get('admin/payment_days/del', data); //删除结算账期

export const payType = (data) => Network.post('admin/pay_type/index', data); //结算方式
export const delPayType = (data) => Network.get('admin/pay_type/del', data); //删除结算方式

export const circuit = (data) => Network.post('admin/circuit/index', data); //线路设置
export const delCircuit = (data) => Network.get('admin/circuit/del', data); //删除线路设置

export const packing = (data) => Network.post('admin/packing/index', data); //包装设置
export const delPacking = (data) => Network.get('admin/packing/del', data); //删除包装设置

export const currency = (data) => Network.post('admin/currency/index', data); //币制
export const delCurrency = (data) => Network.get('admin/currency/del', data); //删除币制

export const internCity = (data) => Network.post('admin/intern_city/index', data); //国际公司
export const delInternCity = (data) => Network.get('admin/intern_city/del', data); //删除国际公司
export const importInternCity = (data) => Network.post('admin/intern_city/import', data); // 国际公司导入

export const aviationCompan = (data) => Network.post('admin/aviation_company/index', data); //航空公司
export const delAviationCompan = (data) => Network.get('admin/aviation_company/del', data); //删除航空公司
export const importAviationCompan = (data) => Network.post('admin/aviation_company/import', data); // 航空公司导入

export const shipCompany = (data) => Network.post('admin/ship_company/index', data); //船公司
export const delShipCompany = (data) => Network.get('admin/ship_company/del', data); //删除船公司
export const importShipCompany = (data) => Network.post('admin/ship_company/import', data); // 船公司导入

export const hsCode = (data) => Network.post('admin/hs_code/index', data); //HS编码
export const delHsCode = (data) => Network.get('admin/hs_code/del', data); //删除HS编码
export const importHsCode = (data) => Network.post('admin/hs_code/import', data); // HS编码导入

// 微信小程序
export const bannerList = (data) => Network.get('admin/banner/index', data); // 轮播图列表
export const bannerShow = (data) => Network.get('admin/banner/show', data); // 轮播图详情
export const bannerAdd = (data) => Network.post('admin/banner/add', data); // 轮播图添加
export const bannerEdit = (data) => Network.post('admin/banner/edit', data); // 轮播图修改
export const bannerDel = (data) => Network.get('admin/banner/del', data); // 轮播图删除

export const serviceList = (data) => Network.get('admin/service_list/index', data); // 服务列表
export const serviceShow = (data) => Network.get('admin/service_list/show', data); // 服务详情
export const serviceAdd = (data) => Network.post('admin/service_list/add', data); // 服务添加
export const serviceEdit = (data) => Network.post('admin/service_list/edit', data); // 服务修改
export const serviceDel = (data) => Network.get('admin/service_list/del', data); // 服务删除

export const helpList = (data) => Network.get('admin/help/index', data); // 帮助中心列表
export const helpShow = (data) => Network.get('admin/help/show', data); // 帮助中心详情
export const helpAdd = (data) => Network.post('admin/help/add', data); // 帮助中心添加
export const helpEdit = (data) => Network.post('admin/help/edit', data); // 帮助中心修改
export const helpDel = (data) => Network.get('admin/help/del', data); // 帮助中心删除

export const packList = (data) => Network.get('admin/pack/index', data); // 包装列表
export const packShow = (data) => Network.get('admin/pack/show', data); // 包装详情
export const packAdd = (data) => Network.post('admin/pack/add', data); // 包装添加
export const packEdit = (data) => Network.post('admin/pack/edit', data); // 包装修改
export const packDel = (data) => Network.get('admin/pack/del', data); // 包装删除

export const aboutShow = (data) => Network.get('admin/about/show', data); // 关于我们详情
export const aboutAdd = (data) => Network.post('admin/about/add', data); // 关于我们添加

export const memberList = (data) => Network.get('admin/member/index', data); // 会员列表
export const memberShow = (data) => Network.get('admin/member/show', data); // 会员详情
export const memberDel = (data) => Network.get('admin/member/del', data); // 会员删除

export const cargoEnquiryList = (data) => Network.get('admin/cargo_enquiry/index', data); // 询价货物列表
export const cargoEnquiryShow = (data) => Network.get('admin/cargo_enquiry/show', data); // 询价货物详情
export const cargoEnquiryEdit = (data) => Network.post('admin/cargo_enquiry/edit', data); // 询价货物修改
export const cargoEnquiryDel = (data) => Network.get('admin/cargo_enquiry/del', data); // 询价货物删除

export const serviceConsultList = (data) => Network.get('admin/service_consult/index', data); // 服务咨询列表
export const serviceConsultShow = (data) => Network.get('admin/service_consult/show', data); // 服务咨询详情
export const serviceConsultEdit = (data) => Network.post('admin/service_consult/edit', data); // 服务咨询修改
export const serviceConsultDel = (data) => Network.get('admin/service_consult/del', data); // 服务咨询删除

// 角色
export const showList = (data) => Network.get('admin/auth/showList', data); // 获取角色列表
export const getGroupList = (data) => Network.get('admin/auth/getGroupList', data); // 根据角色获取权限列表
export const getAuthList = (data) => Network.get('admin/auth/getAuthList', data); // 获取可操作的链接
export const getAuthAdd = (data) => Network.post('admin/auth/saveData', data); // 角色添加
export const getAuthShow = (data) => Network.get('admin/auth/show', data); // 角色详情
export const getAuthEdit = (data) => Network.post('admin/auth/edit', data); // 角色编辑
export const getAuthDel = (data) => Network.get('admin/auth/del', data); // 角色删除
export const getParentGroup = (data) => Network.get('admin/auth/getParentGroup', data); // 父类列表

// 管理员
export const managerList = (data) => Network.get('admin/manager/index', data); // 管理员列表
export const managerShow = (data) => Network.get('admin/manager/show', data); // 管理员详情
export const managerEdit = (data) => Network.post('admin/manager/edit', data); // 管理员修改
export const managerAdd = (data) => Network.post('admin/manager/add', data); // 管理员添加
export const managerDel = (data) => Network.get('admin/manager/del', data); // 管理员删除

// 汇总账单
export const getItemsData = (data) => Network.get('admin/collect_bills/getItemsData', data); // 查询数据返回结果程序pdf
export const setItemsData = (data)	=> Network.get('admin/collect_bills/exportPdf',data);    // 点击导出pdf时调用 用于保存导出pdf数据记录
export const getCollectList = (data)	=> Network.get('admin/collect_bills/index',data);    // 列表
export const setCollectStatus = (data)	=> Network.get('admin/collect_bills/setStatus',data);    // 更新状态
export const delCollect = (data)	=> Network.get('admin/collect_bills/del',data);    // 删除
export const showCollect = (data)	=> Network.get('admin/collect_bills/show',data);    // 详情
export const voucherIndexApi = (data)	=> Network.get('admin/voucher/index',data);    // 凭证类型--获取列表
export const voucherSaveApi = (data)	=> Network.post('admin/voucher/save',data);    // 凭证类型--添加
export const voucherEditApi = (data)	=> Network.post('admin/voucher/edit',data);    // 凭证类型--编辑
export const voucherDelApi = (data)	=> Network.get('admin/voucher/del',data);    // 凭证类型--删除

// 结算管理---应付结算列表
export const getCopeWithApi = (data) => Network.get('admin/collect_bills/getCopeWith', data); // 查询数据返回结果程序pdf
export const exportCopeWithPdfApi = (data) => Network.get('admin/collect_bills/exportCopeWithPdf', data); // 导出pdf时保存
export const exportExcelApi = (data) => Network.get('admin/collect_bills/exportExcel', data); // 汇总账单列表导出execl
export const approvalAddApi = (data) => Network.post('admin/approval_list/add', data); // 财务添加批注
export const updateStatusApi = (data) => Network.post('admin/approval_list/updateStatus', data); // 对财务批注进行审核
export const exportDataApi = (url,data) => Network.get(url, data); //















// 共同方法
let downHttp = location.origin.indexOf('local') > 0 ? 'http://gjwl.maoln.com' : location.origin
export const downHttpApi = (data) => {
  let url = downHttp + "/api/admin/collect_bills/exportExcel?sid="
  return url
}


// 审批
export const financeAgainFun = (that, e) => {
  updateStatusApi({type_id:that.financeData.type_id, source_id:e.id}).then(res=>{
    that.$message({
      message: '审批成功',
      type: 'success'
    });
    let idx = that.list.findIndex(n => n.id === e.id);
    that.list[idx].approval_list.apply_status = 1
  })
};
//关闭
export const getModalCloseFun = (that, e) => {
  that.financeShow = false;
};
//提交
export const getSubmitFun = (that, e) => {
  console.log(e)
  that.financeShow = false;
  that.list[that.financeIdx].approval_list = {
    apply_status: 0,
    remark: e.remark,
    source_id: e.source_id,
  }
};
// 财务审批
export const getFinanceDataFun = (that, e) => {
  that.financeData.source_id = e.id;
  that.financeIdx = that.list.findIndex(n => n.id === e.id)
  that.financeShow = true;
  that.financeData.remark = ''
  console.log(that.financeShow)
};
